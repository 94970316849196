<template>
    <div>
        <a-row style="margin-bottom:10px;">
          <a-form layout="inline">
            <a-form-item label="垃圾类型">
              <a-select style="width: 150px;" @change="handleChange" allowClear>
                  <a-select-option v-for="(item, index) in types" :key="index" :value="item.typeCode">{{item.typeName}}</a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="查询时间">
              <a-range-picker  @change="onChangeTime"/>
            </a-form-item>
          </a-form>
        </a-row>
        <a-table :data-source="data" :pagination="pagination" :columns="columns" :loading="loading" :scroll="{ y: 650 }">
            <span slot="collectTime" slot-scope="text, record">{{record.collectTime}}</span>
        </a-table>
    </div>
</template>

<script>
import util from '@/utils/util'
import moment from 'moment'
import { queryStreetActualApi } from '@/api/actual'
export default {
    data() {
        return{
            loading: false,
            data: [],
            params: {
                page: 1,
                limit: 10,
                startTime: '',
                endTime: ''
            },
            types: [],
            pagination: {
                pageSize: 10,
                current: 1,
                total: 0,
                showQuickJumper: true,
                showSizeChanger: true,
                showTotal: (total) => {
                return '共' + total + '条'
                },
                pageSizeOptions: ['10', '20', '30', '40'],
                onChange: (current, size) => this.changePage(current, size),
                onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
            },
            columns: [
                {
                    title: '小区名称',
                    dataIndex: 'centerName',
                    key: 'centerName'
                },
                {
                    title: '清洁屋',
                    dataIndex: 'houseName',
                    key: 'houseName'
                },
                {
                    title: '垃圾类型',
                    dataIndex: 'typeName',
                    key: 'typeName'
                },
                {
                    title: '重量(kg)',
                    dataIndex: 'weight',
                    key: 'weight'
                },
                {
                    title: '采集时间',
                    dataIndex: 'collectTime',
                    key: 'collectTime',
                    scopedSlots: { customRender: 'collectTime' },
                },
                {
                    title: '采集设备',
                    dataIndex: 'deviceCode',
                    key: 'deviceCode'
                },
                {
                    title: '采集ip',
                    dataIndex: 'ipAddr',
                    key: 'ipAddr'
                }
            ]
        }
    },
    mounted() {
        this.queryStreetActua()
        this.getTypes()
    },
    filters: {
        filterTime(val) {
            return moment(val).format('YYYY-MM-DD HH:mm:ss') === 'Invalid date' ? '' : moment(val).format('YYYY-MM-DD HH:mm:ss')
        }
    },
    methods: {
        getTypes() {
            util.queryCenterGarbageType(this.$route.query.moduleCode).then(res => {
                this.types = res
            })
        },
        handleChange(e) {
            this.params.typeCode = e
            this.changePage(1, 10)
        },
        onChangeTime(e) {
            this.params.startTime = e[0]
            this.params.endTime = e[1]
            this.queryStreetActua()
        },
        queryStreetActua() {
            this.loading = true
            queryStreetActualApi({...this.params}, this.$route.query.moduleCode).then(res => {
                this.data = res.result.content
                this.data.forEach(item => {
                    item.collectTime = moment(item.collectTime).format('YYYY-MM-DD HH:mm:ss')
                })
                this.pagination.total = res.result.totalElements
                this.loading = false
            })
        },
        changePage(current, size){
            this.pagination.current = current
            this.pagination.pageSize = size
            this.params.page = current
            this.params.limit = size
            this.queryStreetActua()
        },
    }
}
</script>